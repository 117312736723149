html {
  font-size: 62.5%;
}

body {
  /* background-color: #fde8e9; */
  background-color: #fafafa;
  color: #1f2232;
  font-family: 'Roboto', sans-serif;
}

.blogContent > pre {
  overflow-wrap: break-word;
  white-space: break-spaces;
}

.blogContent > p {
  overflow-wrap: break-word;
  white-space: break-spaces;
}

.blogContent > p > img {
  width: 75%;
  margin: auto;
  display: block;
}

.layout {
  padding: 0px 25%;
  margin-top: 68px;
  margin-bottom: 32px;
  font-size: 2rem;
}

@media only screen and (max-width: 1024px) {
  .layout {
    padding: 0px 10%;
  }

  .blogContent > p > img {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .layout {
    padding: 0px 16px;
  }

  .tabContainer {
    max-width: initial !important;
  }

  .socialContainer {
    width: 60% !important;
  }

  .projectContainer {
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 420px) {
  .tabBar {
    min-width: initial !important;
  }

  .projectCardContainer {
    width: 100% !important;
  }
}

a {
  text-decoration: none;
  color: #90c;
}

.homeLink {
  color: #90c;
}

img {
  width: 100%;
}

.socialContainer {
  width: 30%;
  display: flex;
  justify-content: space-around;
  margin: 48px auto;
}

.projectContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
  gap: 16px;
}

.tabBar {
  background-color: white;
  position: fixed;
  margin-bottom: 36px;
  width: 100%;
  top: 0;
  min-height: 36px;
  padding: 8px 0px;
  box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.4);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  z-index: 100;
}

.tabContainer {
  margin: auto;
  max-width: 50%;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-evenly;
}

.activeTab::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100% !important;
  background-color: #90c;
  height: 3px;
}

.tab {
  position: relative;
  color: #1f2232;
}

.tab::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 0%;
  background-color: #90c;
  height: 3px;
}

.tab:hover::after {
  transition: all 0.4s ease-in;
  width: 100%;
}

.projectCard {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 6px;
  border: 2px #1f2232 solid;
}

.projectCard:hover {
  transition: all 0.2s;
  transform: scale(1.03);
}

.projectCardContainer {
  width: 45%;
}

.projectCard__content {
  background-color: rgba(12,13,20,0.6);
  color: #fafafa;
  padding: 8px;
}

.projectCard__title {
  margin-bottom: 8px;
}

.projectCard__desc {
  font-size: 1.5rem;
}

.socialLink {
  color: #1f2232;
}

.socialLink:hover {
  color: #90c;
  transition: all 0.2s;
}
